import { Link } from "react-router-dom";
import "./dashbord.css";
import Sidebar from "../Sidebar/Sidebar";
import DashHeader from "./dashheader";
import firebase, {
  database,
  get,
  child,
  remove,
  getDatabase,
} from "../Login/config";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Bar, Doughnut, Line, Pie, PolarArea } from "react-chartjs-2";

import Invoice from "./images/invoice.png";
import Business from "./images/company.png";
import Customer from "./images/customer.png";
import Iteams from "./images/sold-items.png";
import TotalEarning from "./images/total-earning.png";
import Bannerimage from "./images/dashbord_banner.svg";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Dashbord() {
  const UserId = localStorage.getItem("AccessUserId");
  const Username = localStorage.getItem("customer");
  const UserEmail = localStorage.getItem("email");
  const [datas, setData] = useState([]);
  const [daywisedatas, setdaywiseData] = useState([]);
  const [company, setCompany] = useState([]);
  const [item, setitems] = useState("");
  const [count, setCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState();
  const [paidAmount, setPaidAmount] = useState(0);
  const [unPaidAmount, setUnPaidAmount] = useState(0);
  const [TotalCustomer, setTotalCustomer] = useState();

  const [customDates, setCustomDates] = useState({ start: null, end: null });
  const [daywisefiltered, setDaywiseFiltered] = useState([]);
  const [totalSoldItems, setTotalSoldItems] = useState();
  const [filterType, setFilterType] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // Retrieve the data from localStorage
  const companyDataString = localStorage.getItem("business");
  const Symbol = localStorage.getItem("symbol");
  // Parse the JSON string into an object (if the data exists in localStorage)
  const companyData = companyDataString ? JSON.parse(companyDataString) : [];

  // Determine the length: 1 if it's an object, or the actual array length if it's an array
  const length = Array.isArray(companyData)
    ? companyData.length
    : companyData
    ? 1
    : 0;

  // Check the output
  // console.log("Length of companyData:", length);

  useEffect(() => {
    getbilinvoice();
  }, []);
  const getbilinvoice = async () => {
    if (UserId) {
      await get(child(database, `invoiceGenerator/users/${UserId}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setData(Object.values(data?.billInvoice || {}));
            setdaywiseData(Object.values(data?.billInvoice || {}));

            // console.log('Object.values(data?.billInvoice', Object.values(data?.billInvoice[0].id));
            setCompany(Object.values(data?.company || {}));
            let totalCount = 0;
            let totalAmountSum = 0;
            let PaidAmountSum = 0;
            let UnPaidAmountSum = 0;
            let totalSoldItems = 0;
            let uniqueCompanies = new Set();
            Object.values(data?.billInvoice || {}).forEach((invoice) => {
              totalCount += invoice?.items?.length || 0;
              totalAmountSum += parseFloat(invoice?.totalAmount || 0);
              totalSoldItems += invoice?.items?.length || 0;
              if (invoice?.companyId) {
                // console.log(invoice.companyId,"true")
                uniqueCompanies.add(invoice.companyId);
              }
              if (invoice?.paidFlagKey == true) {
                PaidAmountSum += parseFloat(invoice?.totalAmount || 0);
              }
              if (invoice?.paidFlagKey == false) {
                UnPaidAmountSum += parseFloat(invoice?.totalAmount || 0);
              }
            });
            setCount(totalCount);
            totalAmountSum = totalAmountSum.toFixed(2);
            setTotalAmount(totalAmountSum);

            PaidAmountSum = PaidAmountSum.toFixed(2);
            setPaidAmount(PaidAmountSum);

            UnPaidAmountSum = UnPaidAmountSum.toFixed(2);
            setUnPaidAmount(UnPaidAmountSum);
            setTotalCustomer(uniqueCompanies.size);
            setTotalSoldItems(totalSoldItems);
          } else {
            console.log("testing data");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("testing");
    }
  };
  useEffect(() => {
    getbilinvoice();
  }, []);

  const storedPaidCount = Number(localStorage.getItem("paidCount"));
  // console.log(storedPaidCount,"storeed paid count")
  const storedUnpaidCount = Number(localStorage.getItem("unpaidCount") );

  const normalizeDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0); // Set time to midnight
    return normalizedDate;
  };

  const filterInvoices = (filter) => {
    const now = new Date();
    let filtered = [];
    let totalCount = 0;
    let totalAmountSum = 0;
    let PaidAmountSum = 0;
    let UnPaidAmountSum = 0;
    let totalSoldItems = 0;
    switch (filter) {
      case "Last 7 Days":
        filtered = daywisedatas.filter((invoice) => {
          const invoiceDate = new Date(Number(invoice.creationDate * 1000));
          const differenceInTime = now.getTime() - invoiceDate.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          return differenceInDays <= 7;
        });
        break;
      case "Current Month":
        filtered = daywisedatas.filter((invoice) => {
          const invoiceDate = new Date(Number(invoice.creationDate * 1000));
          return (
            invoiceDate.getMonth() === now.getMonth() &&
            invoiceDate.getFullYear() === now.getFullYear()
          );
        });
        break;
      case "Last Month":
        const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        filtered = daywisedatas.filter((invoice) => {
          const invoiceDate = new Date(Number(invoice.creationDate * 1000));
          return (
            invoiceDate.getMonth() === lastMonth.getMonth() &&
            invoiceDate.getFullYear() === lastMonth.getFullYear()
          );
        });
        break;
        case "All":
          getbilinvoice();
          break;
      case "Custom Days":
        if (customDates.start && customDates.end) {
          const startDateNormalized = normalizeDate(customDates.start);
          const endDateNormalized = normalizeDate(customDates.end);

          filtered = daywisedatas.filter((invoice) => {
            const invoiceDate = new Date(Number(invoice.creationDate * 1000));
            const normalizedInvoiceDate = normalizeDate(invoiceDate);

            return (
              normalizedInvoiceDate.getTime() ===
                startDateNormalized.getTime() ||
              normalizedInvoiceDate.getTime() === endDateNormalized.getTime() ||
              (normalizedInvoiceDate >= startDateNormalized &&
                normalizedInvoiceDate <= endDateNormalized)
            );
          });
        }
        break;
      default:
        filtered = daywisefiltered;
    }
    console.log(filtered);
    filtered.forEach((invoice) => {
      totalCount += invoice.items?.length || 0; // Count items in each invoice
      totalAmountSum += parseFloat(invoice.totalAmount || 0); // Total amount of all invoices
      totalSoldItems += invoice.items?.length || 0;
      if (invoice.paidFlagKey === true) {
        PaidAmountSum += parseFloat(invoice.totalAmount || 0); 
        // Paid amount
      }
      if (invoice.paidFlagKey === false) {
        UnPaidAmountSum += parseFloat(invoice.totalAmount || 0); // Unpaid amount
      }
    });
    
   
    setPaidAmount(PaidAmountSum.toFixed(2));
    localStorage.setItem("paid amount",paidAmount);
    // console.log(PaidAmountSum,"hello")
    setUnPaidAmount(UnPaidAmountSum.toFixed(2));
    localStorage.setItem("unpaid amount",unPaidAmount);
    setTotalAmount(totalAmountSum.toFixed(2));
    setTotalSoldItems(totalSoldItems);
    setTotalCustomer(
      new Set(filtered.map((invoice) => invoice.companyId)).size
    );
    setData(filtered);
  };

  useEffect(() => {
    if (filterType) {
      filterInvoices(filterType); // Apply the filter
    }
  }, [filterType]);
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilterType(selectedFilter); // Update the selected filter
    if (selectedFilter === "Custom Days") {
      setIsDialogOpen(true); // Show the date picker
    } else {
      setIsDialogOpen(false); // Hide the date picker
      // Apply the filter logic for other options
      filterInvoices(selectedFilter);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setCustomDates({ start, end });
  };

  const applyCustomDateFilter = () => {
    setIsDialogOpen(false); // Close the dialog
    filterInvoices("Custom Days"); // Apply the custom filter
  };


  //  Date wise filter code end here...........

  return (
    <>
      <Helmet>
        <title>
          Free Invoice Generator Online: Create Invoices Easily | BillNama
        </title>
        {/* <meta
          name="description"
          content="Explore the Invoice mobile app case study by Softnoesis, a testament to our success in mobile development. Discover how we created a user-friendly invoicing solution, enhanced productivity, and streamlined business operations. Click now to dive into this transformative success story!"
        /> */}
        <link rel="canonical" href="https://app.billnama.com/dashboard" />
      </Helmet>
      <div className="dashbord">
        <div className="space"></div>
        <Sidebar />
        <div className="account_details">
          <DashHeader />
          <div className="dashfiltermain">
            <ul>
              <div>
              <li className="dots" id="Filters">
  <div className="select-wrapper">
    <select
      id="Filter_type"
      placeholder="filter"
      value={filterType}
      onChange={handleFilterChange}
    >
      <option>All</option>
      <option value="Last 7 Days">Last 7 Days</option>
      <option value="Current Month">Current Month</option>
      <option value="Last Month">Last Month</option>
      <option value="Custom Days">Custom Days</option>
    </select>
    <i className="fa-solid fa-angle-down"></i>
  </div>
</li>

              </div>
            </ul>
            {/* {filterType === "Custom Days" && customDates.start && customDates.end && (
                        <div>
                          <p>
                            {customDates.start.toLocaleDateString()} to{" "}
                            {customDates.end.toLocaleDateString()}
                          </p>
                        </div>
                      )} */}
          </div>
          <div className="dashbord_main_section">
            <div className="dashbord_banner">
              <div>
                <p>Invoice Your Customer in Seconds</p>
                <h2>
                  Check Your Daily Invoices,
                  <br />
                  Items and Customers
                </h2>
              </div>
              <img src={Bannerimage} />
            </div>
            <div className="subscription_banner">
              <div className="first_chart">
                <Doughnut
                  style={{ width: "320px", height: "320px" }}
                  className="doughnut_chart"
                  data={{
                    labels: ["Paid Invoice", "Unpaid Invoice"],
                    datasets: [
                      {
                        data: [paidAmount, unPaidAmount],
                        backgroundColor: [
                          "rgba(39, 94, 254,0.7)",
                          "rgb(255, 99, 132)",
                          // 'rgb(255, 205, 86)'
                        ],
                        borderColor: [
                          "rgba(39, 94, 254, 1)",   // Border color for Paid Invoice
                          "rgb(255, 99, 132)",      // Border color for Unpaid Invoice
                        ],
                      },
                    ],
                  }}
                />
              </div>
              {/* <div className="second_chart">
                <Line
                  style={{ width: "320px", height: "320px", marginTop: "40px" }}
                  className="doughnut_chart"
                  data={{
                    labels: ["Paid Invoice", "Unpaid Invoice"],
                    datasets: [
                      {
                        data: [storedPaidCount, storedUnpaidCount],
                        backgroundColor: [
                          "rgba(39, 94, 254,0.7)",
                          "rgb(255, 99, 132)",
                          // 'rgb(255, 205, 86)'
                        ],
                      },
                    ],
                  }}
                />
              </div> */}
            </div>
          </div>
          
          <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
            <DialogTitle>Select Custom Date Range</DialogTitle>
            <DialogContent>
              <DatePicker
                selected={customDates.start}
                onChange={handleDateChange}
                startDate={customDates.start}
                endDate={customDates.end}
                selectsRange
                inline
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
              <Button
                onClick={applyCustomDateFilter}
                disabled={!customDates.start || !customDates.end}
              >
                Apply
              </Button>
            </DialogActions>
          </Dialog>

          <div className="details">
          <Link to="#">
              <div className="total_invoice" id="total_solditeam">
                <div className="info">
                  <div>
                    <img src={TotalEarning} />
                  </div>
                  <div>
                    <p>
                      {Symbol} {totalAmount}
                    </p>
                    <h2>Total Earning</h2>
                  </div>
                </div>
              </div>
            </Link>
          <Link to="#">
              <div className="total_invoice" id="total_solditeam">
                <div className="info">
                  <div>
                    <img src={Iteams} />
                  </div>
                  <div>
                    <p>
                      {Symbol} {paidAmount}
                    </p>
                    <h2>Paid Earning</h2>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="#">
              <div className="total_invoice" id="total_solditeam">
                <div className="info">
                  <div>
                    <img src={Iteams} />
                  </div>
                  <div>
                    <p>
                      {Symbol} {unPaidAmount}
                    </p>
                    <h2>Unpaid Earning</h2>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/invoices">
              <div className="total_invoice">
                <div className="info">
                  <div>
                    <img src={Invoice} />
                  </div>
                  <div>
                    <p>{datas.length}</p>
                    <h2>Total Invoice</h2>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/listed-business">
              <div className="total_invoice" id="total_business">
                <div className="info">
                  <div>
                    <img src={Business} />
                  </div>
                  <div>
                    <p>{TotalCustomer}</p>
                    <h2>Total Business</h2>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/customers">
              <div className="total_invoice" id="total_customer">
                <div className="info">
                  <div>
                    <img src={Customer} />
                  </div>
                  <div>
                    <p>{datas.length}</p>
                    <h2>Total Customers</h2>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/sold-products">
              <div className="total_invoice" id="total_solditeam">
                <div className="info">
                  <div>
                    <img src={Iteams} />
                  </div>
                  <div>
                    <p>{totalSoldItems}</p>
                    <h2>Total Sold Items</h2>
                  </div>
                </div>
              </div>
            </Link>
           
            
           
            <Link to="#">
              <div
                className="total_invoice"
                id="total_solditeam"
                style={{ display: "none" }}
              ></div>
            </Link>
          </div>
          <div className="bar_charts">
            {/* <div className="first_chart">
              <Line
                className="line_chart"
                data={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                  datasets: [{
                    label: 'Hide Data',
                    data: [59, 34, 84, 94, 65, 76, 69, 51, 35, 83, 95, 54],
                    backgroundColor: [
                      // 'rgba(255, 99, 132, 0.2)',
                      // 'rgba(255, 159, 64, 0.2)',
                      // 'rgba(255, 205, 86, 0.2)',
                      // 'rgba(75, 192, 192, 0.2)',
                      // 'rgba(54, 162, 235, 0.2)',
                      // 'rgba(153, 102, 255, 0.2)',
                      // 'rgba(201, 203, 207, 0.2)'
                      'rgba(39, 94, 254,0.2)'
                    ],
                    fill: true,
                    borderColor: 'rgba(39, 94, 254)',
                    // tension: 0.1,
                    borderWidth: 1
                  }]
                }}
              />
            </div>
            <div className="first_chart">
              <Doughnut
                style={{ width: "320px", height: "320px" }}
                className="doughnut_chart"
                data={{
                  labels: [
                    'Paid Invoice',
                    'Unpaid Invoice',
                  ],
                  datasets: [{
                    data: [storedPaidCount,storedUnpaidCount ],
                    backgroundColor: [
                      'rgba(39, 94, 254,0.7)',
                      'rgb(255, 99, 132)',
                      // 'rgb(255, 205, 86)'
                    ],
                  }]
                }}
              />
            </div> */}
          </div>
          <div id="dashboard_footer">
            <Footer />
          </div>
          {/* 
          <div className="bar_charts_new_section">
                    <div className="bar_charts_new_section_first_chart">

                    </div>
                    <div className="bar_charts_new_section_second_chart">

                    </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
