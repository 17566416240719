import { Link, json } from 'react-router-dom';
import './New-invoice.css';
import logo from './images/image_2023_04_18T11_21_42_112Z-removebg-preview.png';
import compnylogo from './images/images-removebg-preview (22).png';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import firebase, { database, get, child } from '../Login/config';
import ReactDOM from 'react-dom';
import SignatureCanvas from 'react-signature-canvas';
import Preview from '../previewsidebar/index';
import Loader from '../loader/index';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { WidthFull } from '@mui/icons-material';
import { margin } from '@mui/system';

export default function InvoiceData() {
    const viewcompanyid = localStorage.getItem("company_id");
    const companyName = localStorage.getItem("companyName");
    const address = localStorage.getItem("address");
    const com_emil = localStorage.getItem("com_emil");
    const UserId = localStorage.getItem("AccessUserId");
    const customer = localStorage.getItem("customer");
    const cus_invoice_number = localStorage.getItem("cus_invoice_number");
    const customerGSTNo = localStorage.getItem("customerGSTNo");
    const dueDate = localStorage.getItem("dueDate");
    // const address = localStorage.getItem("address");
    const phone = localStorage.getItem("phone");
    const email = localStorage.getItem("email");
    const cus_note = localStorage.getItem("cus_note");
    const com_terms = localStorage.getItem("com_terms");
    const com_country = localStorage.getItem("com_country")
    const selectedOption=localStorage.getItem("selectedOption");

    // const productData = localStorage.getItem("ProductData",);
    // console.log(productData,JSON.stringify);
    var ProductData = JSON.parse(localStorage.getItem("ProductData"));
    // console.log("heloo", ProductData);
    const terms = localStorage.getItem("terms");
    const symbol = localStorage.getItem("symbol");
    const Growprice = localStorage.getItem("Growprice");
    const Gst = localStorage.getItem("Gst");
    const Tax = localStorage.getItem("tax");
    let Showitems;
    let ShowitemsFirebase;

    // Convert image to base64Img code start
    const inputRef = useRef();
    const [base64Image, setBase64Image] = useState('');
    const imageUrlFromLocalStorage = localStorage.getItem("Compnylogoimage");

    useEffect(() => {
        const convertLocalStorageImageToBase64 = async () => {
            try {
                // Retrieve the image URL from localStorage
                // const imageUrlFromLocalStorage = localStorage.getItem('imageUrl');
                // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
                if (imageUrlFromLocalStorage) {
                    const response = await fetch(`${imageUrlFromLocalStorage}`);
                    const blob = await response.blob();
                    const base64String = await new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.readAsDataURL(blob);
                    });
                    setBase64Image(base64String);
                    console.log(base64String);
                } else {
                    console.error('Image URL not found in localStorage');
                }
            } catch (error) {
                console.error('Error converting image to Base64:', error);
            }
        };

        convertLocalStorageImageToBase64();

    }, [imageUrlFromLocalStorage]);
    // Convert image to base64Img code end...

    // pdf print start
    // const inputRef = useRef();
    const genretpdf = useReactToPrint({
        content: () => inputRef.current,
        documentTitle: "Invoice",
    });

    // pdf print start

    // pdf print start
    const UserName = localStorage.getItem("UserName");
    const NO = localStorage.getItem("invoiceId_no");
    var data = document.getElementById('divToPrint');
    const printDocument = () => {
        const data = document.getElementById('divToPrint');
        html2canvas(data, { allowTaint: true, useCORS: true, type: 'jpeg', quality: 0.20, scale: 2, }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            pdf.addImage(imgData, "png", 5, 7, 200, 280);
            pdf.save(NO);
        });
    };
    // pdf print End

    // Start code getdata using firbase.
    const [datas, setData] = useState();
    const [company, setCompany] = useState();
    let bildata = [];
    let bildatas = [];
    const [isLoading, setIsLoading] = useState(false);
    const getbilinvoice = () => {
        setIsLoading(true);
        get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
            if (snapshot.exists()) {
                bildata = snapshot.val().billInvoice;
                const data = snapshot.val();
                setData(Object.entries(data.billInvoice));
                setCompany(Object.entries(data.company));
                setIsLoading(false);
            }

        }).catch((error) => {
            console.error(error);
        });
    }
    useEffect(() => {
        getbilinvoice();
    }, []);
    const invoice_id = localStorage.getItem("createdInvoiceId");


    // End code getdata using firbase.


    function formatDate(timestamp) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        let date;

        // Check if input is a Unix timestamp (integer or string)
        if (!isNaN(timestamp)) {
            if (timestamp.toString().length === 10) {
                // If it's 10 digits long, treat it as seconds and convert to milliseconds
                date = new Date(timestamp * 1000);
            } else if (timestamp.toString().length === 13) {
                // If it's 13 digits long, treat it as milliseconds
                date = new Date(parseInt(timestamp, 10));
            }
        } else {
            // Try to parse date string in various formats
            if (/\d{2}-\d{2}-\d{4}/.test(timestamp)) {
                // If format is dd-mm-yyyy
                const [day, month, year] = timestamp.split('-');
                date = new Date(`${year}-${month}-${day}`);
            } else if (/\d{4}-\d{2}-\d{2}/.test(timestamp)) {
                // If format is yyyy-mm-dd
                date = new Date(timestamp);
            } else if (/\d{2}-\d{2}-\d{2}/.test(timestamp)) {
                // If format is dd-mm-yy
                const [day, month, year] = timestamp.split('-');
                const fullYear = `20${year}`; // Assuming it's 2000+
                date = new Date(`${fullYear}-${month}-${day}`);
            } else {
                // Fall back to creating Date from any valid date string
                date = new Date(timestamp);
            }
        }

        if (!date || isNaN(date.getTime())) {
            return 'Invalid Date'; // Handle invalid date inputs
        }

        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        return `${day} ${months[monthIndex]}, ${year}`;
    }
    return (

        <>
            <Preview />
            {isLoading ? <Loader /> :

                UserId &&
                <>
                    <Link to="/invoices">
                        <div className='back'>
                            <i className="fa-sharp fa-solid fa-xmark"></i>
                        </div>
                    </Link>
                    <div>
                        <div className='Invoice1'>
                            <div className='Invoices1' ref={inputRef}>
                                <div className='Invoice-details' id="divToPrint" ref={inputRef} style={{ backgroundColor: "#DFF3FD", padding: "0px" }}>

                                    {/* {/ Company details code start  /} */}
                                    {company ? company.map((company_data, i) => {
                                        if (company_data[1].companyId == viewcompanyid) {
                                            { localStorage.setItem("Compnylogoimage", company_data[1].companyPhoto) }
                                            return (
                                                <div className='bussiness-info' style={{ alignItems: "End", paddingBottom: "2px", justifyContent: "space-between", paddingBottom: "10px", backgroundColor: "white", padding: " 10px", }}>
                                                    <div className='bussiness-info-details' style={{ marginLeft: "0px" }}>
                                                        <h3 >{company_data[1].companyName}</h3>

                                                        <p>{Tax}: {company_data[1].gstNumber}</p>
                                                        <p className='adress'>{company_data[1].address}</p>
                                                    </div>
                                                    <div className='bussiness-info-logo'>
                                                        <img src={imageUrlFromLocalStorage} />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }) : "Company Data Not Found"}
                                    {/* {/ Company details code End  /} */}

                                    {/* {/ customer-info start /} */}

                                    {datas ? datas.map((invo_data, index) => {
                                        // {console.log('invoice_id', invoice_id)}
                                        { const totalQuantity = invo_data[1].items.reduce((total, item) => total + item.quantity, 0); }
                                        if (invoice_id == invo_data[1].invoiceId) {
                                            return (
                                                <>
                                                    <div className="customer-info" key={index} style={{ backgroundColor: "white", padding: " 10px", margin: "0px" }}>
                                                        <div className='Cusname-invoice-number'>
                                                            <div className='Cus-name'>
                                                                <span >To, <span>{invo_data[1].customer}</span></span>

                                                                <div className='Adress' style={{ marginTop: "5px" }}>
                                                                    <p>{Tax}: {invo_data[1].customerGSTNo}</p>
                                                                </div>
                                                                <div className='Adress'>
                                                                    <p>{invo_data[1].address}</p>
                                                                </div>

                                                                <div className='number'>
                                                                    <p>{invo_data[1].phone
                                                                    }</p>
                                                                </div>

                                                                <div className='Email'>
                                                                    <p>{invo_data[1].email}</p>
                                                                </div>
                                                            </div>
                                                            <div className='invoice-number'>
                                                                <div>
                                                                    {(() => {
                                                                        if (invo_data && invo_data[1] && invo_data[1].id) {
                                                                            localStorage.setItem("invoiceId_no", invo_data[1].id);
                                                                            return (
                                                                                <span>
                                                                                    Invoice: <span>{invo_data[1].id}</span>
                                                                                </span>
                                                                            );
                                                                        }
                                                                        return null; // Return null if data isn't available yet
                                                                    })()}
                                                                </div>
                                                                <div className='Date'>
                                                                    <span style={{ display: "block", marginBottom: "5px" }}>
                                                                        Date: <span>{formatDate(invo_data[1].date)}</span>
                                                                    </span>
                                                                    <span>
                                                                        Due Date: <span>{formatDate(invo_data[1].dueDate)}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    {/* {/ customer-info Code End /} */}

                                                    <div className='purshcing-info'>

                                                        <table style={{ textAlign: 'left', width: '100%', fontSize: '14px', borderCollapse: "collapse", marginBottom: "10px", backgroundColor: "#D1EFFC" }}>
                                                            <thead>
                                                                <tr style={{ backgroundColor: "#59C9FA", fontWeight: "500" }}>

                                                                    <th style={{ padding: " 3px", width: "20px", fontWeight: "500", paddingBottom: "5px" }}>No</th>
                                                                    <th style={{ padding: " 3px", width: "40%", fontWeight: "500", paddingBottom: "5px" }}>Item</th>
                                                                    <th style={{ padding: " 3px", fontWeight: "500", paddingBottom: "5px" }}>{selectedOption === "Hours" ? "Hours" : "Qty"}</th>
                                                                    <th style={{ padding: " 3px", fontWeight: "500", paddingBottom: "5px" }}>Price</th>
                                                                    <th style={{ padding: " 3px", fontWeight: "500", paddingBottom: "5px" }}>Amount</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {invo_data[1].items.map((items, i) => (
                                                                    <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "#9CDEFB" : "transparent", }}>

                                                                        <td style={{ padding: "5px 3px", textAlign: "center" }} valign="top">{i + 1}</td>
                                                                        <td style={{ padding: "5px 3px", }} valign="top">
                                                                            <div>{items.name}</div>
                                                                            <div style={{ color: "dimgray", fontSize: "12px" }}>{items.subtitle}</div>
                                                                        </td>
                                                                        <td style={{ padding: "5px 3px" }} valign="top">{items.quantity}</td>
                                                                        <td style={{ padding: "5px 3px" }} valign="top">{symbol} {items.price}</td>
                                                                        <td style={{ padding: "5px 3px" }} valign="top">{symbol} {items.amount}</td>

                                                                    </tr>
                                                                ))}
                                                                <tr style={{ borderTop: "0.1em solid #4BA2FF", borderBottom: "0.1em solid #4BA2FF", }}>

                                                                    <td style={{ padding: " 3px 3px 10px 3px" }}></td>
                                                                    <td style={{ padding: " 3px 3px 10px 3px" }}></td>
                                                                    <td style={{ padding: "3px 3px 10px 3px" }}>{invo_data[1].items.reduce((total, item) => total + parseFloat(item.quantity || 0), 0)}</td>
                                                                    <td style={{ padding: "3px 3px 10px 3px" }}></td>

                                                                    <td style={{ padding: "3px 3px 10px 3px" }}>{symbol} {invo_data[1].items.reduce((total, item) => total + parseFloat(item.amount || 0), 0).toFixed(2)}</td>

                                                                </tr>
                                                                <tr >

                                                                    <td style={{ padding: "5px 3px" }}></td>
                                                                    <td style={{ padding: "5px 3px" }}></td>
                                                                    <td style={{ padding: "5px 3px" }}></td>
                                                                    <td style={{ padding: "5px 3px" }}>{Tax} ({invo_data[1].gstPercetage}.0 %):</td>
                                                                    <td style={{ padding: "5px 3px" }}>{symbol}&nbsp;{invo_data[1].gstAmount}</td>

                                                                </tr>
                                                                <tr>

                                                                    <td style={{ padding: "5px 3px" }}></td>
                                                                    <td style={{ padding: "5px 3px" }}></td>
                                                                    <td style={{ padding: "5px 3px" }}></td>
                                                                    <td style={{ padding: "5px 3px" }}>Disc ({invo_data[1].discountPercetage}%):</td>
                                                                    <td style={{ padding: " 3px" }}>{symbol} -{invo_data[1].discountAmount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ padding: " 3px" }}></td>
                                                                    <td style={{ padding: " 3px" }}></td>
                                                                    <td style={{ padding: " 3px" }}></td>
                                                                    <td style={{ padding: " 3px", fontSize: "15px", backgroundColor: "#59C9FA", }}><b>Payable Amount:</b></td>
                                                                    <td style={{ padding: " 3px", fontSize: "15px", backgroundColor: "#59C9FA" }}><b>{symbol} {invo_data[1].totalAmount}</b></td>
                                                                </tr>
                                                            </tbody>                          </table>
                                                        {/* purshcing-info-section3 Start*/}

                                                        {/* <div className='iteam-total'>

                                                            <div className='total-Amount'>
                                                                <span>{symbol}<span>{invo_data[1].total}</span></span>
                                                            </div>
                                                        </div> */}


                                                        {/* purshcing-info-section3  End*/}
                                                    </div>
                                                    <div style={{ position: "absolute", width: "100%", bottom: "0px", left: "0px", padding: "5px" }}>
                                                        <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", padding: "5px 10px", fontSize: "13px", marginBottom: "5px" }}>
                                                            <div className='Note' style={{ width: "60%" }}>
                                                                <span><b>Note:</b></span>
                                                                <ul style={{ listStyle: "none", marginTop: "10px" }}>
                                                                    <li>{invo_data[1].note}</li>
                                                                </ul>
                                                            </div>
                                                            <div className="signeture-mens">
                                                                <div className='signature'>
                                                                    <div className='sig-box'>

                                                                    </div>
                                                                    {company ? company.map((company_data, i) => {
                                                                        if (company_data[1].companyId == viewcompanyid) {
                                                                            return (
                                                                                <p style={{ color: "dimgray", fontSize: "15px" }}><b>Signature of {company_data[1].companyName}</b></p>
                                                                            )
                                                                        }
                                                                    }) : "Company Data Not Found"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='Tearms-section' style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", borderTop: "1px solid #4BA2FF", padding: "2px 10px", fontSize: "13px" }}>
                                                            <div className='Tearms'>
                                                                <span><b>Terms:</b></span>
                                                                <ul style={{ listStyle: "none" }}>
                                                                    {company ? company.map((company_data, i) => {
                                                                        if (i === company.length - 1) {
                                                                            return (
                                                                                <li>{company_data[1].terms} </li>
                                                                            )
                                                                        }
                                                                    }) : "Company Data Not Found"}
                                                                </ul>
                                                            </div>
                                                            <div className='Reciver-sig'>
                                                                <p style={{ color: "dimgray", fontSize: "15px" }}><b>Receiver Signature</b></p>

                                                            </div>
                                                        </div>
                                                    </div>


                                                    <p style={{ position: "absolute", width: "100%", bottom: "-28px", left: "0px", padding: "5px", color: "dimgray", fontSize: "14px" }}>Powered by BillNama.com</p>
                                                </>
                                            )
                                        }
                                    }) : "Invoice Data Not Found"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="buttons" id="pdf" onClick={printDocument}>
                        <span class="button-content">Download </span>
                    </button>
                    <button class="buttons" id="pdfprint" onClick={genretpdf}>
                        <span class="button-content">Print</span>
                    </button>
                </>
            }
            {/* <button ><i className="fa-solid fa-cloud-arrow-down"></i> Download</button> */}
        </>
    );
}