import "./Invoicedata.css";
import Headers from "../Header/index";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import firebase, {
  database,
  get,
  child,
  remove,
  getDatabase,
} from "../Login/config";
import React, { useEffect, useState } from "react";
import { ref, update, set } from "firebase/database";

import DatanotFound from "./images/rMSD8h3gzM.gif";
import Invoiceheaderiimage from "./images/hading_image.png";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/database";
import Sidebar from "../Sidebar/Sidebar";
import DashHeader from "../Dashbord/dashheader";
import Country from "../../COMPONENTS/countries.json";
import Currancy from "../../COMPONENTS/currency.json";
import Loader from "../loader/index";
import Footer from "../Footer/Footer";
import Devlopment from "../Still_devlopment_poppup/index";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Add popup box code start;

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Helmet } from "react-helmet";
const date = new Date();
const Day = date.getDate();
const month = date.toLocaleString("default", { month: "long" });
const Year = date.getFullYear();

// Add popup box code end

export default function Invoicedata() {
  const selectedCompanyId = localStorage.getItem("selectedCompanyId");
  const companyName = localStorage.getItem("companyName");
  const navigate = useNavigate();
  const [datas, setData] = useState();
  const [companydata, setCompany] = useState();
  let bildata = [];
  let companyid = localStorage.getItem("company_id");
  const UserId = localStorage.getItem("AccessUserId");
  const [invoiceid, setInvoiceId] = useState("");
  const [firebaseinvoiceid, setFirebaseInvoiceId] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsupdate, setItemsupdate] = useState([{}]);
  const [total, setTotal] = useState(0);
  const [updateitems, setUpdateitem] = useState([]);
  const [daywisefiltering, setdaywisefiltering] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
const [isPaid, setIsPaid] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [customDates, setCustomDates] = useState({ start: null, end: null });

  // const [items, setItems] = useState([]); // items fetched from Firebase
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("selectedOption") || "Quantity" // Default to "Quantity"
  );
  const [note, setnote] = useState("");

  //   const handleSelectChange = (e) => {
  //     const selectedItemId = e.target.value;
  //     // Update state
  //     setSelectedOption(selectedItemId);
  //     // Update localStorage
  //     localStorage.setItem("selectedCompanyId", selectedItemId);
  //   };
  const creationDate = Day + "  " + month + ", " + Year;

  const [invoices, setInvoices] = useState([]);
  const [filterType, setFilterType] = useState("");
  // const [companydata, setCompany] = useState();
  const getbilinvoice = () => {
    get(child(database, `invoiceGenerator/users/${UserId}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          if (data && data.billInvoice) {
            const invoiceArray = Object.entries(data.billInvoice).map(
              ([key, value]) => ({
                id: key,
                ...value,
              })
            );
            // console.log(invoiceArray)
            setInvoices(invoiceArray);

            setCompany(Object.entries(data.company));
            setData(Object.entries(data.billInvoice));
          } else {
            console.error("billInvoice data is undefined or null");
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // localStorage.setItem("company_id")
    getbilinvoice();
  }, []);
  const venderId = Math.floor(Math.random() * 9000000000000) + 1;
  const [Symbol, setSymbol] = useState(localStorage.getItem("symbol"));
  //   console.log(Symbol);

  // const [Symbol, setSymbol] = useState(localStorage.getItem("symbol") || "");

  //   // Update the symbol when localStorage changes
  //   useEffect(() => {
  //     const handleStorageChange = () => {
  //       setSymbol(localStorage.getItem("symbol"));
  //     };

  //     window.addEventListener("storage", handleStorageChange);

  //     // Cleanup the event listener when the component is unmounted
  //     return () => {
  //       window.removeEventListener("storage", handleStorageChange);
  //     };
  //   }, [Symbol]);

  //  View Invoice Data Code Start

  const handleClickes = (event, id) => {
    event.preventDefault();
    localStorage.setItem("invoice_id", event.target.value);
    localStorage.setItem("createdInvoiceId", event.target.value);
    localStorage.setItem("company_id", id);
    navigate("/myinvoice");
  };
  //  View Invoice Data Code End

  //  Delete Invoice Data Code Start
  const handledelete = (event) => {
    // Log the value of event.target.value for debugging
    console.log("Invoice ID to delete:", event.target.value);

    // Set invoice ID in localStorage
    localStorage.setItem("invoice_id", event.target.value);

    // Get invoice ID from localStorage
    const billinvoice_id = localStorage.getItem("invoice_id");
    // Log the value of billinvoice_id for debugging
    console.log("Invoice ID from localStorage:", billinvoice_id);

    // Remove data from Firebase Realtime Database
    remove(
      ref(
        getDatabase(),
        `invoiceGenerator/users/${UserId}/billInvoice/${billinvoice_id}`
      )
    )
      .then(() => {
        console.log("Data deleted successfully.");
        // Reload the page after data deletion
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        // Handle error, if any
      });
  };

  const opens = () => {
    // localStorage.setItem("invoice_id", event.target.value);
    document.getElementById("Delete").style.display = "block";
  };

  const close = () => {
    document.getElementById("Delete").style.display = "none";
  };
  //  Delete Invoice Data Code End

  // confirmation popup box code start
  const [open, setOpen] = React.useState(false);

  //open when click on update button
  const handleClickOpen = (invoice_id) => {
    // Store the selected ID in localStorage
    localStorage.setItem("Update_Invoice_Id", invoice_id);


    // Set the state for the current invoice ID and open the dialog
    setFirebaseInvoiceId(invoice_id);
    setOpen(true);

    // Fetch data from Firebase
    get(child(database, `invoiceGenerator/users/${UserId}/billInvoice`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();

          // Retrieve the stored ID from localStorage
          const billinvoice_id = localStorage.getItem("Update_Invoice_Id");
          console.log("Stored Invoice ID:", billinvoice_id);

          // Filter the data to find the matching invoice
          const matchedData = Object.entries(data).find(
            ([key]) => key === billinvoice_id
          );
          console.log("Fetched Data from Firebase:", data);
          if (matchedData) {
            const [id, details] = matchedData;
            console.log("Matched Invoice Data:", details);
            const note = details.note;
            console.log("note is:", note);
            setnote(note);
            const updateitems = details.items;
            console.log("Items fetched from Firebase:", updateitems);
            setitems(updateitems); // Update the state with the fetched items
            console.log("updated items", updateitems);
            setUpdateitem({ id, ...details });
            setIsPaid(details.paidFlagKey || false); 
          } else {
            console.error("No matching invoice found for the stored ID.");
          }
        } else {
          console.error("No data found in Firebase.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data from Firebase:", error);
      });
  };

  //update function start
  const handleUpdate = () => {
    // Retrieve the current invoice ID from localStorage
    const billinvoice_id = localStorage.getItem("Update_Invoice_Id");
    localStorage.setItem("selectedOption", selectedOption);
    if (billinvoice_id) {
      
      // Calculate the total amount based on the items array
      const totalAmount = items
        .reduce((total, item) => {
          const amount = parseFloat(item.amount) || 0; // Ensure valid amount is used
          return total + amount;
        }, 0)
        .toFixed(2); // Total amount should be a string with two decimal places

        // const isPaid = paidFlag;
      // Prepare the updated invoice data
      const updatedInvoice = {
        ...updateitems, // Keep the existing invoice data
        items: items.map((item, index) => {
          // Calculate the amount for each item
          const amount = (
            parseFloat(item.quantity) * parseFloat(item.price)
          ).toFixed(2); // Use qty and price to calculate the amount
          console.log("Calculated amount:", amount);

          // Exclude unwanted fields and return only relevant fields
          const { qty, dse, amt, item: itemName, ...filteredItem } = item; // Exclude unwanted fields (qty, dse, item)

          return {
            ...filteredItem, // Keep the filtered item data
            amount: amount, // Add the calculated amount
            billId: billinvoice_id, // Use the existing billId
            updateDate: Date.now().toString(),
            companyId: companyid, // Add the current timestamp for update
            creationDate: creationDate,
            itemId: String(venderId),
          };
        }),
        totalAmount: totalAmount,
        note: note,
        paidFlagKey: isPaid,
        // Add the total amount to the invoice data
      };
      console.log(paidFlagKey)

      // Initialize Firebase database reference
      const db = getDatabase();
      const invoiceRef = ref(
        db,
        `invoiceGenerator/users/${UserId}/billInvoice/${billinvoice_id}`
      );
      setIsPaid(isPaid)
      // Update the invoice data in Firebase
      set(invoiceRef, updatedInvoice)
        .then(() => {
          console.log("Invoice data updated successfully!");
          toast("Updated Succesfully!..");
          // Close the dialog after the update
          setOpen(false);
          getbilinvoice();
        })
        .catch((error) => {
          console.error("Error updating invoice data:", error);
        });
    } else {
      console.error("No invoice ID found in localStorage.");
    }
  };

  //update function end

  const handleClose = () => {
    setOpen(false);
  };
  // confirmation popup box code end

  const [items, setitems] = useState([
    { item: "", dse: "", quantity: "", price: "", amount: "" },
  ]);
  const add_iteam = () => {
    setitems([
      ...items,
      { item: "", dse: "", quantity: "", price: "", amount: "" },
    ]);
  };
  const remove_iteam = (index) => {
    const list = [...items];
    list.splice(index, 1);
    setitems([...list]);
  };

  useEffect(() => {
    const calculatedTotal = items.reduce((sum, item) => {
      const amount = parseFloat(item.amount) || 0; // Ensure valid number
      return sum + amount;
    }, 0);
    setTotal(calculatedTotal.toFixed(2)); // Update the total with two decimal places
  }, [items]);

  // Apply tax code start

  const [Taxs, setTax] = useState("");
  let CurrencyCode = localStorage.getItem("currencyCode");
  const TaxType = () => {
    if (
      CurrencyCode == "EUR" &&
      CurrencyCode == "CZK" &&
      CurrencyCode == "DKK" &&
      CurrencyCode == "HUF" &&
      CurrencyCode == "PLN" &&
      CurrencyCode == "RON" &&
      CurrencyCode == "SEK" &&
      CurrencyCode == "GBP"
    ) {
      setTax("VAT");
    } else if (CurrencyCode == "INR") {
      setTax("GST");
    } else {
      setTax("TAX");
    }
  };
  useEffect(() => {
    TaxType();
  }, [Taxs]);
  const [taxvalue, settaxvalue] = useState("");
  const handleClick = (value) => {
    settaxvalue(value);
  };
  const [discount, setdiscount] = useState("");
  const handleClicks = (value) => {
    setdiscount(value);
  };

  // Apply tax code end

  // tax and discount code start

  const TotalAmount = localStorage.getItem("total");
  // console.log(TotalAmount)
  const Growprice = parseInt(TotalAmount);
  // console.log(Growprice)
  const GST = (Growprice * taxvalue) / 100;
  const gstAmount = GST;
  const gstPercetage = taxvalue;
  const TaxAmount = (Growprice + GST).toFixed(2);
  const discountAmount = ((TaxAmount * discount) / 100).toFixed(2);
  const discountPercetage = discount;
  localStorage.setItem("DiscountAmounts", discountAmount);
  const totalAmount = (Growprice + GST - discountAmount).toFixed(2);
  localStorage.setItem("totalAmount", totalAmount);

  // tax and discount code end
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  // paid unpaid start

  // Initialize state variable for checkbox value

  

  const handleToggleChange = () => {
    const newValue = !paidFlagKey;
    console.log("new value:",newValue)
    setIsPaid(newValue);
    // paidFlagKey
    // console.log(newValue);
  };
  const paidFlagKey = isPaid;
  const Customeplaceholder = "Enter" + Taxs;
  // paid unpaid end

  //   currency start
  const [selectedItem, setSelectedItem] = useState(null);
  //   const [searchQuery, setSearchQuery] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(
    Country.CurrencyResponse
  );
  const handleItemClick = (result) => {
    setSelectedItem(result.name);
    console.log("selectedItem", selectedItem);
    localStorage.setItem("selectedItem", result.name);
  };

  //   currency End

  function formatDate(timestamp) {
    // console.log('timestamp', timestamp)
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    // console.log('date', date)
    return `${day} ${months[monthIndex]}, ${year}`;
  }

  // Filter data using company name code start...

  const [selectedCompany, setSelectedCompany] = useState(); // State to hold selected company ID
  const [filteredData, setFilteredData] = useState([]);
  const [daywisefiltered, setdaywisefiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Function to handle change in dropdown
  const handleCompanyChange = (event) => {
    const companyId = event.target.value; 
    console.log("company is is:",companyId)// Capture the selected company ID
    localStorage.setItem("company_id",companyId)
    setSelectedCompany(companyId); // Update state
  };

  useEffect(() => {
    if (selectedCompany && datas.length > 0) {
      const newData = datas.filter((invo_data) => {
        // Check if companyId matches either as a string or number
        return (
          invo_data[1].companyId === selectedCompany ||
          invo_data[1].companyId === Number(selectedCompany)
        );
      });
      setFilteredData(newData); // Update the filtered data
      setdaywisefiltered(newData);
      setIsLoading(false); // Set loading to false
    } else {
      setFilteredData([]); // Reset filtered data if no selection
      setIsLoading(false); // Ensure loading is false in this case as well
    }
  }, [selectedCompany, datas]);

  // Render component logic...

  useEffect(() => {
    if (companydata && companydata.length > 0) {
      setSelectedCompany(companydata[0][1].companyId);
      localStorage.setItem("company_id",companydata[0][1].companyId)
    }
  }, [companydata]);

  const [sortBy, setSortBy] = useState(""); // State to hold the sorting criteria

  // Event handler for dropdown change
  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  // Sort function based on different criteria
  const sortData = (data, sortBy) => {
    switch (sortBy) {
      case "Sort By Name":
        return data.sort((a, b) => a[1].customer.localeCompare(b[1].customer));
      case "Sort By Date":
        return data.sort(
          (a, b) => new Date(a[1].creationDate) - new Date(b[1].creationDate)
        );
      case "Sort By Amount":
        return data.sort((a, b) => a[1].totalAmount - b[1].totalAmount);
      case "Sort By Paid":
        const paidData = data.filter(
          (item) => item[1].paidFlagKey.toString() === "true"
        );
        const unpaidData = data.filter(
          (item) => item[1].paidFlagKey.toString() !== "true"
        );
        return [...paidData.sort(), ...unpaidData.sort()];
      case "Sort By Unpaid":
        const unpaidDataFirst = data.filter(
          (item) => item[1].paidFlagKey.toString() !== "true"
        );
        const paidDataFirst = data.filter(
          (item) => item[1].paidFlagKey.toString() === "true"
        );
        return [...unpaidDataFirst.sort(), ...paidDataFirst.sort()];
      default:
        return data;
    }
  };

  // useEffect to set default sorting when component mounts
  useEffect(() => {
    setSortBy("Sort By Name");
  }, []);

  const Show = () => {
    document.getElementById("devlopment-popups").style.display = "block";
  };

  // count how many paid and unpaid data  start
  const [paidCount, setPaidCount] = useState(0);
  const [unpaidCount, setUnpaidCount] = useState(0);

  // Count paid and unpaid items and store in local storage on component mount
  useEffect(() => {
    if (datas && datas.length > 0) {
      let paidCount = 0;
      let unpaidCount = 0;

      datas.forEach((invo_data) => {
        if (invo_data[1].paidFlagKey.toString() === "true") {
          paidCount++;
        } else {
          unpaidCount++;
        }
      });

      // Store the counts in local storage
      localStorage.setItem("paidCount", paidCount);
      localStorage.setItem("unpaidCount", unpaidCount);

      // Update state
      setPaidCount(paidCount);
      setUnpaidCount(unpaidCount);
    }
  }, [filteredData]);

  // count how many paid and unpaid data  End

  // Date wise filter code start here............
  const normalizeDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0); // Set time to midnight
    return normalizedDate;
  };

  const filterInvoices = (filter) => {
    const now = new Date();
    let filtered = [];

    switch (filter) {
      case "Last 7 Days":
        filtered = daywisefiltered.filter((invoice) => {
          const invoiceDate = new Date(Number(invoice[1].creationDate * 1000));
          const differenceInTime = now.getTime() - invoiceDate.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          return differenceInDays <= 7;
        });
        break;
      case "Current Month":
        filtered = daywisefiltered.filter((invoice) => {
          const invoiceDate = new Date(Number(invoice[1].creationDate * 1000));
          return (
            invoiceDate.getMonth() === now.getMonth() &&
            invoiceDate.getFullYear() === now.getFullYear()
          );
        });
        break;
      case "Last Month":
        const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        filtered = daywisefiltered.filter((invoice) => {
          const invoiceDate = new Date(Number(invoice[1].creationDate * 1000));
          return (
            invoiceDate.getMonth() === lastMonth.getMonth() &&
            invoiceDate.getFullYear() === lastMonth.getFullYear()
          );
        });
        break;
      case "Custom Days":
        if (customDates.start && customDates.end) {
          const startDateNormalized = normalizeDate(customDates.start);
          const endDateNormalized = normalizeDate(customDates.end);
  
          filtered = daywisefiltered.filter((invoice) => {
            const invoiceDate = new Date(Number(invoice[1].creationDate * 1000));
            const normalizedInvoiceDate = normalizeDate(invoiceDate);
  
            return (
              normalizedInvoiceDate.getTime() === startDateNormalized.getTime() ||
              normalizedInvoiceDate.getTime() === endDateNormalized.getTime() ||
              (normalizedInvoiceDate >= startDateNormalized && normalizedInvoiceDate <= endDateNormalized)
            );
          });
        } 
        break;
      default:
        filtered = daywisefiltered;
    }
    setFilteredData(filtered);
    
  };

  useEffect(() => {
    if (filterType) {
      filterInvoices(filterType); // Apply the filter
    }
  }, [filterType]);
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilterType(selectedFilter); // Update the selected filter
    if (selectedFilter === "Custom Days") {
      setIsDialogOpen(true); // Show the date picker
    } else {
      setIsDialogOpen(false); // Hide the date picker
      // Apply the filter logic for other options
      filterInvoices(selectedFilter);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setCustomDates({ start, end });
  };

  const applyCustomDateFilter = () => {
    setIsDialogOpen(false); // Close the dialog
    filterInvoices("Custom Days"); // Apply the custom filter
  };
  //  Date wise filter code end here...........

  const handlevalueChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  return (
    <>
      <Helmet>
        <title>
          Manage Your Invoices Data Easily with our Invoice Generator Tool
        </title>
        {/* <meta
          name="description"
          content="Explore the Invoice mobile app case study by Softnoesis, a testament to our success in mobile development. Discover how we created a user-friendly invoicing solution, enhanced productivity, and streamlined business operations. Click now to dive into this transformative success story!"
        /> */}
        <link rel="canonical" href="https://app.billnama.com/invoices" />
      </Helmet>

      <div className="dashbord">
        <div className="space"></div>
        <Sidebar />
        {isLoading ? (
          // Loader component here
          <Loader />
        ) : (
          <>
            <div className="account_details">
              <DashHeader />
              <div className="invoice_info">
                <div className="invoices_details">
                  <div className="invoices_head">
                    <div className="invoices-container">
                      {/* <h2>Invoices</h2> */}
                      <ul>
                        {/* <li>Dashbord</li> */}
                        {/* <li>invoices</li> */}
                        <li id="Serch_customer" className="dots">
                          <div>
                            <div>
                              <p>Search</p>
                            </div>
                            <i class="fa-solid fa-magnifying-glass"></i>
                            <input
                              type="search"
                              placeholder="Search Invoice"
                              onChange={handleSearch}
                            />
                          </div>
                        </li>
                        <li className="dots">
                          <div>
                            <div className="business_dropdown_lable">
                              <p>Business</p>
                            </div>
                            {UserId && (
                              <div className="business_dropdown">
                                <i className="fa-solid fa-angle-down"></i>
                                <select
                                  onChange={handleCompanyChange}
                                  value={selectedCompany}
                                >
                                  {companydata
                                    ? companydata.map((company_data, i) => {
                                        return (
                                          <option
                                            key={i}
                                            value={company_data[1].companyId}
                                          >
                                            {company_data[1].companyName}
                                          </option>
                                        );
                                      })
                                    : ""}
                                </select>
                              </div>
                            )}
                          </div>
                        </li>

                        <li className="dots">
                          <div>
                            <div className="sortby_dropdown_lable">
                              <p>Sort by</p>
                            </div>
                            <div className="another_dropdown">
                              <i className="fa-solid fa-angle-down"></i>
                              <select
                                className="dropdown"
                                onChange={handleSortChange}
                              >
                                {/* Populate options for the second dropdown */}
                                <option disabled>Choose Option</option>
                                <option>Sort By Name </option>
                                <option>Sort By Date</option>
                                <option>Sort By Amount</option>
                                <option>Sort By Paid</option>
                                <option>Sort By Unpaid</option>
                              </select>
                            </div>
                          </div>
                        </li>
                        <div>
                       <ul>
                        <div>
                          <li className="dots" id="Filter">
                            <div>
                              <div>
                                <p>Filter</p>
                              </div>
                              <i className="fa-solid fa-angle-down"></i>
                              <select
                                id="Filter_type"
                                placeholder="filter"
                                value={filterType}
                                onChange={handleFilterChange}
                              >
                                <option >All</option>
                                <option value="Last 7 Days">Last 7 Days</option>
                                <option value="Current Month">
                                  Current Month
                                </option>
                                <option value="Last Month">Last Month</option>
                                <option value="Custom Days">Custom Days</option>
                              </select>
                            </div>
                          </li>
                        </div>
                      </ul>
                      <b>
                      {filterType === "Custom Days" && customDates.start && customDates.end && (
                        <div>
                          <p>
                            {customDates.start.toLocaleDateString()} to{" "}
                            {customDates.end.toLocaleDateString()}
                          </p>
                        </div>
                      )}
                      </b>
                    </div>
                      </ul>


                    </div>

                    {/* <div>
                      <ul>
                        <div>
                          <li className="dots" id="Filter">
                            <div>
                              <div>
                                <p>Filter</p>
                              </div>
                              <i className="fa-solid fa-angle-down"></i>
                              <select
                                id="Filter_type"
                                placeholder="filter"
                                value={filterType}
                                onChange={handleFilterChange}
                              >
                                <option >All</option>
                                <option value="Last 7 Days">Last 7 Days</option>
                                <option value="Current Month">
                                  Current Month
                                </option>
                                <option value="Last Month">Last Month</option>
                                <option value="Custom Days">Custom Days</option>
                              </select>
                            </div>
                          </li>
                        </div>
                      </ul>
                      <b>
                      {filterType === "Custom Days" && customDates.start && customDates.end && (
                        <div>
                          <p>
                            {customDates.start.toLocaleDateString()} to{" "}
                            {customDates.end.toLocaleDateString()}
                          </p>
                        </div>
                      )}
                      </b>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="invoice_info">
                <div className="main_section" id="main_section">
                  <div className="main_section_heading">
                    <div>
                      <p>Id</p>
                      <p>Customer Name</p>
                      <p>Date</p>
                      <p>Amount</p>
                      <p>Status</p>
                    </div>
                    <div>
                      <p>Action</p>
                    </div>
                  </div>
                  <Dialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                  >
                    <DialogTitle>Select Custom Date Range</DialogTitle>
                    <DialogContent>
                      <DatePicker
                        selected={customDates.start}
                        onChange={handleDateChange}
                        startDate={customDates.start}
                        endDate={customDates.end}
                        selectsRange
                        inline
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setIsDialogOpen(false)}>
                        Cancel
                      </Button>
                      <Button
                        onClick={applyCustomDateFilter}
                        disabled={!customDates.start || !customDates.end}
                      >
                        Apply
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {(UserId && (
                    <div className="fiebase_details">
                      {filteredData &&
                      filteredData.length > 0 &&
                      sortData(filteredData, sortBy).some((invo_data) =>
                        invo_data[1].customer
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      ) ? (
                        sortData(filteredData, sortBy).map(
                          (invo_data, index) => {
                            const timestamp = invo_data[1].creationDate;
                            if (
                              invo_data[1].customer
                                ?.toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            ) {
                              return (
                                <>
                                  <div
                                    className="data_his"
                                    id="data_his"
                                    key={index}
                                  >
                                    <div className="data">
                                      <div className="invoice_data">
                                        <p>
                                          <button
                                            onClick={(event) =>
                                              handleClickes(
                                                event,
                                                invo_data[1].companyId
                                              )
                                            }
                                            data-value={invo_data[1].companyId}
                                            value={invo_data[0]}
                                          >
                                            #{invo_data[1].id}
                                          </button>
                                        </p>
                                        <p>
                                          <button
                                            onClick={(event) =>
                                              handleClickes(
                                                event,
                                                invo_data[1].companyId
                                              )
                                            }
                                            data-value={invo_data[1].companyId}
                                            value={invo_data[0]}
                                          >
                                            {invo_data[1].customer}
                                          </button>
                                        </p>
                                        <p>
                                          <button
                                            onClick={(event) =>
                                              handleClickes(
                                                event,
                                                invo_data[1].companyId
                                              )
                                            }
                                            data-value={invo_data[1].companyId}
                                            value={invo_data[0]}
                                          >
                                            {formatDate(timestamp)}
                                          </button>
                                        </p>
                                        <p>
                                          <button
                                            onClick={(event) =>
                                              handleClickes(
                                                event,
                                                invo_data[1].companyId
                                              )
                                            }
                                            data-value={invo_data[1].companyId}
                                            value={invo_data[0]}
                                          >
                                            {Symbol} {invo_data[1].totalAmount}
                                          </button>
                                        </p>
                                        <p>
                                          {
                                          (invo_data[1].paidFlagKey.toString() ==
                                            "true" && (
                                            <lable id="paid"> paid </lable>
                                          )) || (
                                            <lable id="unpaid"> unpaid </lable>
                                          )}
                                        </p>
                                        {/* <p></p> */}
                                      </div>
                                    </div>
                                    {invoices.map(() => {})}
                                    <div className="btn">
                                      {/* update button */}
                                      <button
                                        onClick={() =>
                                          handleClickOpen(invo_data[0])
                                        }
                                      >
                                        <i className="fa-regular fa-pen-to-square"></i>
                                      </button>

                                      {/* <button
                                                         onClick={Show}
                                                         disabled={invo_data[1].paidFlagKey.toString() === 'true'}
                                                         style={{
                                                            opacity: invo_data[1].paidFlagKey.toString() === 'true' ? 0.5 : 1,
                                                            cursor: invo_data[1].paidFlagKey.toString() === 'true' ? 'no-drop' : 'pointer',
                                                         }}
                                                      >
                                                         <i className="fa-regular fa-pen-to-square"></i>
                                                      </button> */}

                                      {/* View button */}
                                      <button
                                        onClick={(event) =>
                                          handleClickes(
                                            event,
                                            invo_data[1].companyId
                                          )
                                        }
                                        data-value={invo_data[1].companyId}
                                        value={invo_data[1].invoiceId}
                                        className="fa-solid fa-eye"
                                      ></button>
                                      {/* delete button */}
                                      <button
                                        onClick={opens}
                                        value={invo_data[0]}
                                      >
                                        <i className="fa-solid fa-trash"></i>
                                      </button>

                                      {/* <button onClick={handledelete} value={invo_data[0]}><i className="fa-solid fa-trash"></i></button> */}
                                    </div>
                                  </div>

                                  {/* Delete popup HTML code start here.... */}
                                  <div id="Delete">
                                    <div className="delete_section">
                                      <div>
                                        <p>
                                          <i className="fa-solid fa-circle-exclamation"></i>
                                        </p>
                                        <h2>Delete Invoice</h2>
                                        <span>
                                          Are You sure You Want to Delete!
                                        </span>
                                        <div className="delete_btn">
                                          <button
                                            onClick={handledelete}
                                            value={invo_data[0]}
                                          >
                                            Delete
                                          </button>
                                          <button onClick={close}>
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Delete popup HTML code end here.. */}

                                  {/* Update popup HTML code start here.. */}
                                  <React.Fragment>
                                    <Dialog
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="alert-dialog-title"
                                      aria-describedby="alert-dialog-description"
                                    >
                                      <DialogContent id="dilog_box">
                                        <DialogContentText id="alert-dialog-description">
                                          <div className="Form">
                                            <form>
                                              <div
                                                className="Bill-item"
                                                id="Bill-item"
                                              >
                                                <div className="items_heading">
                                                  <div className="items_heding">
                                                    <span>Item Name</span>
                                                  </div>
                                                  <div className="items_fulldetails">
                                                    <p>
                                                      <select value={selectedOption} onChange={handlevalueChange}>
                                                        <option value="Quantity">
                                                          Quantity
                                                        </option>
                                                        <option value="Hours">Hours</option>
                                                      </select>
                                                    </p>
                                                    {/* Quantity / Hours */}
                                                    <p>Rate</p>
                                                    <p>AMOUNT</p>
                                                  </div>
                                                </div>

                                                {items.map((item, index) => {
                                                  // if (Array.isArray(items)) {
                                                  //    total = items.reduce((sum, item) => {
                                                  //        const amount = parseFloat(item.amount) || 0;
                                                  //        return sum + amount;
                                                  //    }, 0); // Start with an initial sum of 0
                                                  // }
                                                  // total=total+item.amount

                                                  // console.log(item.amount)

                                                  // Store the total in localStorage after calculation

                                                  const handleItemChange = (
                                                    e,
                                                    field,
                                                    index
                                                  ) => {
                                                    // Create a copy of the items array
                                                    const updatedItems = [
                                                      ...items,
                                                    ];

                                                    // Update the specific field of the item at the given index
                                                    updatedItems[index] = {
                                                      ...updatedItems[index],
                                                      [field]: e.target.value,
                                                    };

                                                    // If the field being updated is qty or price, recalculate the amount
                                                    if (
                                                      field === "quantity" ||
                                                      field === "price"
                                                    ) {
                                                      const updatedItem =
                                                        updatedItems[index];
                                                      const quantity =
                                                        parseFloat(
                                                          updatedItem.quantity
                                                        ) || 0; // Default to 0 if qty is empty or invalid
                                                      // console.log(qty)
                                                      const price =
                                                        parseFloat(
                                                          updatedItem.price
                                                        ) || 0; // Default to 0 if price is empty or invalid
                                                      const amount = (
                                                        quantity * price
                                                      ).toFixed(2); // Calculate the amount for the item
                                                      updatedItems[index] = {
                                                        ...updatedItem,
                                                        amount,
                                                      }; // Update the amount field
                                                    }

                                                    // Update the state with the modified items array
                                                    setitems(updatedItems);

                                                    // Recalculate the total dynamically
                                                    const newTotal =
                                                      updatedItems.reduce(
                                                        (sum, item) => {
                                                          const amount =
                                                            parseFloat(
                                                              item.amount
                                                            ) || 0; // Ensure amount is parsed safely
                                                          return sum + amount; // Add the amount to the running total
                                                        },
                                                        0
                                                      );

                                                    // Update the total state
                                                    setTotal(newTotal); // Update the total amount state
                                                  };

                                                  localStorage.setItem(
                                                    "total",
                                                    total
                                                  );

                                                  return (
                                                    <>
                                                      <div
                                                        className="Add-item"
                                                        id="add-iteam"
                                                      >
                                                        <p key={index}>{index+1}</p>
                                                        <div className="Item">
                                                          <input
                                                            name="item"
                                                            placeholder="Enter Item Name"
                                                            value={
                                                              item.name || ""
                                                            }
                                                            onChange={(e) =>
                                                              handleItemChange(
                                                                e,
                                                                "name",
                                                                index
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                        <div className="Quantity">
                                                          <input
                                                            type="number"
                                                            min="0"
                                                            name="quantity"
                                                            placeholder="0"
                                                            value={
                                                              item.quantity ||
                                                              ""
                                                            }
                                                            onChange={(e) =>
                                                              handleItemChange(
                                                                e,
                                                                "quantity",
                                                                index
                                                              )
                                                            }
                                                          />

                                                          {/* <label>Quantity/Hours*</label> */}
                                                        </div>
                                                        <div className="Price">
                                                          <input
                                                            type="number"
                                                            min="0"
                                                            name="price"
                                                            placeholder="00.00"
                                                            value={
                                                              item.price || ""
                                                            }
                                                            onChange={(e) =>
                                                              handleItemChange(
                                                                e,
                                                                "price",
                                                                index
                                                              )
                                                            }
                                                          />

                                                          {/* <label>Rate*</label> */}
                                                        </div>
                                                        <div
                                                          className="Grows-price"
                                                          id="amount"
                                                        >
                                                          {/* <p >Amount</p> */}
                                                          <span>
                                                            {item.price *
                                                              item.quantity ||
                                                              item.quantity *
                                                                item.price ||
                                                              "0"}
                                                          </span>
                                                        </div>
                                                        {index == 0 ? (
                                                          <div
                                                            className="remove"
                                                            style={{
                                                              visibility:
                                                                "hidden",
                                                            }}
                                                          >
                                                            <i
                                                              className="fa-solid fa-xmark"
                                                              onClick={() =>
                                                                remove_iteam(
                                                                  index
                                                                )
                                                              }
                                                            ></i>
                                                          </div>
                                                        ) : (
                                                          <div className="remove">
                                                            <i
                                                              className="fa-solid fa-xmark"
                                                              onClick={() =>
                                                                remove_iteam(
                                                                  index
                                                                )
                                                              }
                                                            ></i>
                                                          </div>
                                                        )}
                                                      </div>

                                                      <div>
                                                        <div
                                                          className="Description"
                                                          id="Description"
                                                        >
                                                          <input
                                                            placeholder="Enter Item Details"
                                                            value={
                                                              item.subtitle ||
                                                              ""
                                                            }
                                                            onChange={(e) =>
                                                              handleItemChange(
                                                                e,
                                                                "subtitle",
                                                                index
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </>
                                                  );
                                                })}
                                                <div
                                                  className="new-add"
                                                  id="new-add"
                                                >
                                                  <button
                                                    className="AddItem"
                                                    type="button"
                                                    onClick={add_iteam}
                                                  >
                                                    <span className="pluse">
                                                      +
                                                    </span>
                                                    <span>Add a New item</span>
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="Total-price">
                                                <div
                                                  className="notes"
                                                  id="notes"
                                                >
                                                  <p>Note</p>
                                                  <textarea
                                                    className="note"
                                                    id="note"
                                                    name="note"
                                                    placeholder="Write here..."
                                                    value={note}
                                                    onChange={(e) =>
                                                      setnote(e.target.value)
                                                    }
                                                  ></textarea>
                                                </div>
                                                <div className="Total-price-details">
                                                  <div
                                                    className="curency"
                                                    id="customer_curency"
                                                  >
                                                    <select
                                                      id="custmer_currency_value"
                                                      onChange={(e) => {
                                                        const [
                                                          currencyCode,
                                                          symbol,
                                                          name,
                                                        ] =
                                                          e.target.value.split(
                                                            ","
                                                          );
                                                        localStorage.setItem(
                                                          "currencyCode",
                                                          currencyCode
                                                        );
                                                        localStorage.setItem(
                                                          "symbol",
                                                          symbol
                                                        );
                                                        localStorage.setItem(
                                                          "selectedItem",
                                                          name
                                                        );
                                                        setSymbol(symbol); // Update the state
                                                        console.log(
                                                          "Updated Symbol:",
                                                          symbol
                                                        );
                                                      }}
                                                    >
                                                      {filteredCountries.length >
                                                      0 ? (
                                                        filteredCountries.map(
                                                          (result, index) => (
                                                            <>
                                                              <option
                                                                selected={
                                                                  localStorage.getItem(
                                                                    "currencyCode"
                                                                  ) ==
                                                                  result
                                                                    .currency
                                                                    .code
                                                                    ? true
                                                                    : false
                                                                }
                                                                value={
                                                                  result
                                                                    .currency
                                                                    .code +
                                                                  "," +
                                                                  result
                                                                    .currency
                                                                    .symbol +
                                                                  "," +
                                                                  result.name
                                                                }
                                                                className="CurrencyResponse"
                                                                key={index}
                                                              >
                                                                <span>
                                                                  {
                                                                    result
                                                                      .currency
                                                                      .code
                                                                  }
                                                                  &nbsp;
                                                                </span>
                                                                <span>
                                                                  (
                                                                  {
                                                                    result
                                                                      .currency
                                                                      .symbol
                                                                  }
                                                                  )
                                                                </span>
                                                              </option>
                                                            </>
                                                          )
                                                        )
                                                      ) : (
                                                        <>
                                                          <div id="datanot_found">
                                                            <h2>
                                                              Data not found
                                                            </h2>
                                                          </div>
                                                        </>
                                                      )}
                                                    </select>
                                                  </div>
                                                  <div className="Price">
                                                    <p>Gross Amount</p>
                                                    <p>
                                                      {Symbol} {total}{" "}
                                                    </p>
                                                  </div>
                                                  <div className="gst">
                                                    <p>{Taxs} </p>
                                                    <p>
                                                      <b>
                                                        <select
                                                          onChange={(e) => {
                                                            handleClick(
                                                              e.currentTarget
                                                                .value
                                                            );
                                                          }}
                                                        >
                                                          {Array.from(
                                                            Array(100),
                                                            (e, i) => {
                                                              return (
                                                                <option
                                                                  value={i}
                                                                >
                                                                  {i}{" "}
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                          ;
                                                        </select>
                                                        <span> % </span>
                                                      </b>
                                                    </p>
                                                    <p>{GST}</p>
                                                  </div>
                                                  <div className="discount">
                                                    <p>Discount</p>
                                                    <p>
                                                      <b>
                                                        <select
                                                          onChange={(e) => {
                                                            handleClicks(
                                                              e.currentTarget
                                                                .value
                                                            );
                                                          }}
                                                        >
                                                          {Array.from(
                                                            Array(100),
                                                            (e, i) => {
                                                              return (
                                                                <option
                                                                  value={i}
                                                                >
                                                                  {i}
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                        </select>
                                                        <span> % </span>
                                                      </b>
                                                    </p>
                                                    <p>{discountAmount}</p>
                                                  </div>
                                                  <div className="Net-Amount">
                                                    <p>
                                                      <b>Payable Amount</b>
                                                    </p>
                                                    <p>
                                                      <b>
                                                        {Symbol}
                                                        {totalAmount}{" "}
                                                      </b>
                                                    </p>
                                                  </div>
                                                  <div className="paid_unpaid">
                                                    <div>
                                                      <label class="switch">
                                                        <input
                                                          id="checkbox1"
                                                          type="checkbox"
                                                          checked={isPaid}
                                                          onChange={
                                                            handleToggleChange
                                                          }
                                                        />
                                                        <span class="slider round"></span>
                                                      </label>
                                                    </div>
                                                    <p>
                                                      {isPaid
                                                        ? "Paid"
                                                        : "Unpaid"}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </DialogContentText>
                                      </DialogContent>
                                      <DialogActions id="update_btn">
                                        <Button onClick={handleClose}>
                                          Cancel
                                        </Button>
                                        <Button
                                          autoFocus
                                          onClick={handleUpdate}
                                        >
                                          Update
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </React.Fragment>
                                  {/* Update popup HTML code end here.. */}
                                </>
                              );
                            } else {
                              return null;
                            }
                          }
                        )
                      ) : (
                        <div id="datanot_found">
                          <img src={DatanotFound} alt="Data Not Found" />
                        </div>
                      )}
                    </div>
                  )) || (
                    <div id="datanot_found">
                      <img src={DatanotFound} />
                    </div>
                  )}
                </div>
                <div className="allrights_recives">
                  <Footer />
                </div>
              </div>
              <Devlopment />
            </div>
          </>
        )}
      </div>
    </>
  );
}
