import firebase, { database, get, child } from "../Login/config";
import "./solditem.css";
import Header from "../Header";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import DashHeader from "../Dashbord/dashheader";
import Invoiceheaderiimage from "../Invoicedata/images/hading_image.png";
import DatanotFound from "../Customer/images/rMSD8h3gzM.gif";
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Solditem() {
  const Symbol = localStorage.getItem("symbol");
  const [datas, setDatas] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const UserId = localStorage.getItem("AccessUserId");
  const [companydata, setCompany] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [Maxtotle, setMaxtotle] = useState(0); // New state for the total value
  const [filterType, setFilterType] = useState("");

  // State to hold the filtered invoice data

  // State to hold the custom date range
  const [customDates, setCustomDates] = useState({ start: null, end: null });

  // State to control the visibility of the date picker dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Placeholder for daywisefiltered (you need to define or fetch this data)
  const daywisefiltered = [];
  const getbilinvoice = () => {
    get(child(database, `invoiceGenerator/users/${UserId}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setCompany(Object.entries(data.company));
          setDatas(Object.entries(data.billInvoice));
          // setdayfiltereddata(Object.entries(data.billInvoice));
          // console.log("company data is",datas)
          setSearchQuery(
            Object.entries(data.billInvoice).flatMap((entry) => entry[1].items)
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filterdata = (search) => {
    const filteredItems = datas.flatMap((entry) =>
      entry[1].items.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    );
    setSearchQuery(filteredItems);
  };

  const handleCompanyChange = (event) => {
    const companyId = Number(event.target.value); // Convert to number here
    setSelectedCompany(companyId);
    // console.log(companyId);
  };

  useEffect(() => {
    getbilinvoice();
  }, []);

  useEffect(() => {
    if (selectedCompany && datas.length > 0) {
      const selectedCompanyId = Number(selectedCompany);
      const newData = datas.filter((invo_data) => {
        return invo_data[1].companyId === selectedCompanyId;
      });

      setFilteredData(newData);
      setIsLoading(false);
    } else {
      setFilteredData([]);
    }
  }, [selectedCompany, datas]);

  useEffect(() => {
    if (companydata && companydata.length > 0) {
      setSelectedCompany(companydata[0][1].companyId);
    }
  }, [companydata]);

  // Calculate total amount when filteredData or searchQuery changes
  useEffect(() => {
    let total = 0;
    (filteredData.length > 0
      ? filteredData.flatMap((entry) => entry[1].items)
      : searchQuery
    ).forEach((item) => {
      if (item.companyId == selectedCompany) {
        total += parseFloat(item.price);
      }
    });
    setTotalAmount(total);
  }, [filteredData, searchQuery, selectedCompany]);

  // Calculate total count when filteredData or searchQuery changes
  useEffect(() => {
    let total = 0;
    (filteredData.length > 0
      ? filteredData.flatMap((entry) => entry[1].items)
      : searchQuery
    ).forEach((item) => {
      if (item.companyId == selectedCompany) {
        total += parseFloat(item.quantity);
      }
    });
    setTotalCount(total);
  }, [filteredData, searchQuery, selectedCompany]);

  // Calculate total for Maxtotle (sum of item.price * item.quantity)
  useEffect(() => {
    let total = 0;
    (filteredData.length > 0
      ? filteredData.flatMap((entry) => entry[1].items)
      : searchQuery
    ).forEach((item) => {
      if (
        String(item.companyId) === String(selectedCompany) ||
        !item.companyId
      ) {
        total += item.price * (item.quantity || 1); // Calculate total for each item
      }
    });
    setMaxtotle(total);
  }, [filteredData, searchQuery, selectedCompany]);

  // Date wise filter code start here............
  const normalizeDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0); // Set time to midnight
    return normalizedDate;
  };

  const [dayfiltereddata, setdayfiltereddata] = useState([]);
  const filterInvoices = (filter) => {
    const now = new Date();
    let filtered = [];

    switch (filter) {
      case "Last 7 Days":
        filtered = datas.filter((invoice) => {
          const invoiceDate = new Date(Number(invoice[1].creationDate * 1000));
          const differenceInTime = now.getTime() - invoiceDate.getTime();
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);
          return differenceInDays <= 7;
        });
        break;
      case "Current Month":
        filtered = datas.filter((invoice) => {
          const invoiceDate = new Date(Number(invoice[1].creationDate * 1000));
          return (
            invoiceDate.getMonth() === now.getMonth() &&
            invoiceDate.getFullYear() === now.getFullYear()
          );
        });
        break;
      case "Last Month":
        const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        filtered = datas.filter((invoice) => {
          const invoiceDate = new Date(Number(invoice[1].creationDate * 1000));
          return (
            invoiceDate.getMonth() === lastMonth.getMonth() &&
            invoiceDate.getFullYear() === lastMonth.getFullYear()
          );
        });
        break;
      case "Custom Days":
        if (customDates.start && customDates.end) {
          const startDateNormalized = normalizeDate(customDates.start);
          const endDateNormalized = normalizeDate(customDates.end);

          filtered = datas.filter((invoice) => {
            const invoiceDate = new Date(
              Number(invoice[1].creationDate * 1000)
            );
            const normalizedInvoiceDate = normalizeDate(invoiceDate);

            return (
              normalizedInvoiceDate.getTime() ===
                startDateNormalized.getTime() ||
              normalizedInvoiceDate.getTime() === endDateNormalized.getTime() ||
              (normalizedInvoiceDate >= startDateNormalized &&
                normalizedInvoiceDate <= endDateNormalized)
            );
          });
        }
        break;
      default:
        filtered = datas;
    }
    setdayfiltereddata(filtered);
    console.log("filtered datas are:", filteredData);
  };

  useEffect(() => {
    if (filterType) {
      filterInvoices(filterType); // Apply the filter
    }
  }, [filterType]);
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilterType(selectedFilter); // Update the selected filter
    if (selectedFilter === "Custom Days") {
      setIsDialogOpen(true); // Show the date picker
    } else {
      setIsDialogOpen(false); // Hide the date picker
      // Apply the filter logic for other options
      filterInvoices(selectedFilter);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setCustomDates({ start, end });
  };

  const applyCustomDateFilter = () => {
    setIsDialogOpen(false); // Close the dialog
    filterInvoices("Custom Days"); // Apply the custom filter
  };

  useEffect(() => {
    setdayfiltereddata(datas || []); // Populate dayfiltereddata with initial data
  }, [datas]);

  return (
    <>
      <Helmet>
        <title>
          Easily Manage Your Sold Product details with Our Inventory Feature
        </title>
        <link rel="canonical" href="https://app.billnama.com/sold-products" />
      </Helmet>
      <div className="dashbord">
        <div className="space"></div>
        <Sidebar />
        <div className="account_details">
          <DashHeader />
          <div className="invoice_info">
            <div className="invoices_details">
              <div className="invoices_head">
                <div>
                  {/* <h2>Sold items</h2> */}
                  <ul>
                    <li id="Serch_customer">
                      <div>
                        <div>
                          <p>Search</p>
                        </div>

                        <i className="fa-solid fa-magnifying-glass"></i>
                        <input
                          type="search"
                          placeholder="Search Items"
                          onChange={(e) => filterdata(e.target.value)}
                          style={{ backgroundColor: "white" }}
                        />
                      </div>
                    </li>

                    <li className="dots">
                      <div>
                        <div className="business_dropdown_lable">
                          <p>Business</p>
                        </div>
                        {UserId && (
                          <div className="business_dropdown">
                            <i className="fa-solid fa-angle-down"></i>
                            <select
                              onChange={handleCompanyChange}
                              value={selectedCompany}
                            >
                              {companydata
                                ? companydata.map((company_data, i) => {
                                    return (
                                      <option
                                        key={i}
                                        value={company_data[1].companyId}
                                      >
                                        {company_data[1].companyName}
                                      </option>
                                    );
                                  })
                                : ""}
                            </select>
                          </div>
                        )}
                      </div>
                    </li>
                    <div>
                    <li className="dots" id="Filter">
                      <div>
                        <div>
                          <p>Filter</p>
                        </div>
                        <i className="fa-solid fa-angle-down"></i>
                        <select
                          id="Filter_type"
                          placeholder="filter"
                          value={filterType}
                          onChange={handleFilterChange}
                        >
                          <option>All</option>
                          <option>Last 7 Days</option>
                          <option>Current Month</option>
                          <option>Last Month</option>
                          <option>Custom Days</option>
                        </select>
                      </div>
                    </li>
                    <b>
                    {filterType === "Custom Days" &&
                      customDates.start &&
                      customDates.end && (
                        <div>
                          <p>
                            {customDates.start.toLocaleDateString()} to{" "}
                            {customDates.end.toLocaleDateString()}
                          </p>
                        </div>
                      )}
                      </b>
                    </div>
                    
               
              
                  </ul>
                  
                </div>
              </div>
            </div>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
              <DialogTitle>Select Custom Date Range</DialogTitle>
              <DialogContent>
                <DatePicker
                  selected={customDates.start}
                  onChange={handleDateChange}
                  startDate={customDates.start}
                  endDate={customDates.end}
                  selectsRange
                  inline
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
                <Button
                  onClick={applyCustomDateFilter}
                  disabled={!customDates.start || !customDates.end}
                >
                  Apply
                </Button>
              </DialogActions>
            </Dialog>

            <div
              style={{
                height: "55vh",
                overflowY: "auto",
                backgroundColor: "white",
                borderRadius: "5px 5px 0px 0px",
                boxShadow:
                  "rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                border: "1px solid rgb(223, 223, 223)",
                borderBottom: "none",
              }}
            >
              <table
                style={{
                  width: "100%",
                  textAlign: "left",
                  backgroundColor: "white",
                  padding: "10px",
                  borderCollapse: "collapse",
                  color: "dimgray",
                  fontSize: "15px",
                }}
              >
                <thead
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#3963AD",
                    zIndex: "1",
                    color: "black",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  <tr>
                    <th style={{ width: "40%", padding: "15px " }}>Items</th>
                    <th style={{ width: "20%", padding: "15px" }}>Quantity</th>
                    <th style={{ width: "20%", padding: "15px" }}>Price</th>
                    <th style={{ width: "20%", padding: "15px" }}>
                      Total Amount
                    </th>
                  </tr>
                </thead>
                <tbody style={{ margin: "0px" }}>
                  {UserId &&
                  (filteredData.length > 0 || searchQuery.length > 0) &&
                  dayfiltereddata.length > 0 ? (
                    <>
                      {/* {console.log("Filtered data in JSX:", filteredData)} */}
                      {(filteredData.length > 0
                        ? filteredData.flatMap((entry) => entry[1].items)
                        : searchQuery
                      ).map((item, i) =>
                        String(item.companyId) === String(selectedCompany) ||
                        !item.companyId ? (
                          <tr
                            key={i}
                            style={{
                              borderBottom: "1px solid rgba(92, 92, 92, 0.10)",
                            }}
                          >
                            <td style={{ padding: "15px" }}>
                              {item.name || "No Name"}
                            </td>
                            <td style={{ padding: "15px" }}>
                              {item.quantity ? `${item.quantity}` : "0"}
                            </td>
                            <td style={{ padding: "15px" }}>
                              {Symbol} {item.price || 0}
                            </td>
                            <td style={{ padding: "15px" }}>
                              {Symbol} {item.price * (item.quantity || 1)}
                            </td>
                          </tr>
                        ) : null
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="4" id="datanot_found">
                        <img src={DatanotFound} alt="Data Not Found" />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                borderRadius: "0px 0px 5px 5px",
                backgroundColor: "white",
                boxShadow:
                  "rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                border: "1px solid rgb(223, 223, 223)",
                fontWeight: "bold",
              }}
            >
              <table
                style={{
                  width: "100%",
                  textAlign: "left",
                  backgroundColor: "white",
                  borderCollapse: "collapse",
                  color: "dimgray",
                  fontSize: "15px",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: "40%", padding: "8px 15px" }}>TOTAL</td>
                    <td style={{ width: "20%", padding: "8px 15px" }}>
                      {totalCount}
                    </td>
                    <td style={{ width: "20%", padding: "8px 15px" }}>
                      {Symbol} {totalAmount}
                    </td>
                    <td style={{ width: "20%", padding: "8px 15px" }}>
                      {Symbol} {Maxtotle}
                    </td>{" "}
                    {/* Display total */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
