import React from 'react';
import Headers from './COMPONENTS/Header/index';
import Homes from './COMPONENTS/Home/index';
import Logins from './COMPONENTS/Login/index';
import Invoices from './COMPONENTS/Invoice/index';
import Newinvoice from './COMPONENTS/New-invoice/index';
import Secondinvoice from './COMPONENTS/Secondinvoice/index';
import Threeinvoice from './COMPONENTS/threeinvoice/index';
import Footers from './COMPONENTS/Footer/index';
import Subscriptions from './COMPONENTS/Subscription/index';
import Details from './COMPONENTS/details/index';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Invoicedatas from "./COMPONENTS/Invoicedata/index";
import Currancys from './COMPONENTS/Currancy';
import Bussiness from './COMPONENTS/Bussiness';
import Customers from "./COMPONENTS/Customer/index"
import Solditems from './COMPONENTS/Solditeams/index';
import { useState, useEffect } from 'react';
import Sessions from './COMPONENTS/Session/index';
import Invoice from "./COMPONENTS/New-invoice/Invoice-view";
import InvoicePrivew from "./COMPONENTS/New-invoice/New-invoice";
import StagingLogin from "./COMPONENTS/Staginglogin/index";
import CustomerForm from "./COMPONENTS/Customer-Form/index";
import Dashbord from './COMPONENTS/Dashbord/index';
import Loader from './COMPONENTS/loader/index';
import Previewsidebar from './COMPONENTS/previewsidebar/index';
import Fourthtemplate from './COMPONENTS/Fourthtemplat/index';
import Fivetemplate from './COMPONENTS/Fivetemplate/index';
import Sixtemplate from './COMPONENTS/Sixtemplate/index';
import Sevantemplate from './COMPONENTS/sevantemplate/index';
import { Cookies } from "react-cookie";
import Updateitem from "./COMPONENTS/Customer-Form/updatedata";
import { Login } from '@mui/icons-material';
import PrivacyPolicy from './COMPONENTS/Privacy-Policy/privacy_policy';
import Tearmsofuse from "./COMPONENTS/Privacy-Policy/tearms_of_use";
import Datadeletion from "./COMPONENTS/Privacy-Policy/data_deletion";
import Refundcancelling from "./COMPONENTS/Privacy-Policy/refund_cancelling";
import Contactus from "./COMPONENTS/Privacy-Policy/contect_us";
import Devlopmentpoppup from './COMPONENTS/Still_devlopment_poppup/Devlopment_poppup';
import Payment from './COMPONENTS/Payment/payment';
import { ToastContainer } from 'react-toastify';
import Success from './COMPONENTS/Payment/Sucess';
import Rejectpopupss from './COMPONENTS/Payment/Reject';
// import Demo from "./COMPONENTS/demo/index";

export default function App() {

  const [loading, setLoading] = useState();
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000)
  })
  const cookeis = new Cookies();
  // const UserAccess = localStorage.getItem("SecUserName");
  const UserAccess = cookeis.get("SecUserName");

  const AccessUserId = localStorage.getItem("AccessUserId");
  return (
    <>
    <ToastContainer />
      {/* <Routes>
        <Route path='/login' element={<Logins />} />
        <Route path='/' element={<Homes />} />
        <Route path='/invoice' element={<Invoices />} />
        <Route path='/invoice1' element={<Newinvoice />} />
        <Route path='/invoice2' element={<Secondinvoice />} />
        <Route path='/invoice3' element={<Threeinvoice />} />
        <Route path='/invoice-form' element={<Details />} />
        <Route path='/select-templet' element={<Selectsections />} />
        <Route path='/subscriptions' element={<Subscriptions />} />
        <Route path='/invoicedatas' element={<Invoicedatas />} />
        <Route path='/myinvoice' element={<Invoice />} />
        <Route path='/currancy' element={<Currancys />} />
        <Route path='/bussiness' element={<Bussiness />} />
        <Route path='/customers' element={<Customers />} />
        <Route path='/solditems' element={<Solditems />} />
        <Route path='/session' element={<Sessions />} />
      </Routes> */}
      {/* <Router>
      <Routes>
       <Route path='/authlogin' element={!UserAccess ? <StagingLogin />  : <Navigate to="/" replace />} />
        <Route path='/login' element={UserAccess ? <Logins /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/' element={UserAccess ? <Homes /> : <Navigate to="/authlogin" replace />} />
        <Route path='/invoice' element={UserAccess ? <Invoices /> : <Navigate to="/authlogin" replace />} />
        <Route path='/invoice1' element={UserAccess ? <Newinvoice /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/invoice2' element={UserAccess ? <Secondinvoice /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/invoice3' element={UserAccess ? <Threeinvoice /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/invoice-form' element={UserAccess ? <Details /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/subscriptions' element={UserAccess ? <Subscriptions /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/invoicedatas' element={UserAccess ? <Invoicedatas /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/myinvoice' element={UserAccess ? <Invoice /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/currancy' element={UserAccess ? <Currancys /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/bussiness' element={UserAccess ? <Bussiness /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/customers' element={UserAccess ? <Customers /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/solditems' element={UserAccess ? <Solditems /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/session' element={UserAccess ? <Sessions /> : <Navigate to="/authlogin" replace /> } />
        <Route path='/customerinfo' element={UserAccess ? <CustomerForm /> : <Navigate to="/authlogin" replace /> } />
         <Route path='/dashbord' element={UserAccess ? <Dashbord /> : <Navigate to="/authlogin" replace /> } />
      </Routes>
</Router> */}
      {/* <Router>
        <Routes>
          <Route path='/staging/authlogin' element={!UserAccess ? <StagingLogin /> : <Navigate to="/" replace />} />
          <Route path='/staging/login' element={UserAccess ? <Logins /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/' element={UserAccess ? <Homes /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/invoice' element={UserAccess ? <Invoices /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/invoice1' element={UserAccess ? <Newinvoice /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/invoice2' element={UserAccess ? <Secondinvoice /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/invoice3' element={UserAccess ? <Threeinvoice /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/invoice4' element={UserAccess ? <Fourthtemplate /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/invoice5' element={UserAccess ? <Fivetemplate /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/invoice6' element={UserAccess ? <Sixtemplate /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/invoice7' element={UserAccess ? <Sevantemplate /> : <Navigate to="/staging/authlogin" replace />} />
          {<Route path='/staging/invoice-form' element={UserAccess ? <Details /> : <Navigate to="/staging/authlogin" replace />} />}
          <Route path='/staging/subscriptions' element={UserAccess ? <Subscriptions /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/invoicedatas' element={UserAccess ? <Invoicedatas /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/myinvoice' element={UserAccess ? <Invoice /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/currancy' element={UserAccess ? <Currancys /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/bussiness' element={UserAccess ? <Bussiness /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/customers' element={UserAccess ? <Customers /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/solditems' element={UserAccess ? <Solditems /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/session' element={UserAccess ? <Sessions /> : <Navigate to="/staging/authlogin" replace />} />
          <Route path='/staging/customerinfo' element={UserAccess ? <CustomerForm /> : <Navigate to="/authlogin" replace />} />
          <Route path='/staging/dashbord' element={UserAccess ? <Dashbord /> : <Navigate to="/authlogin" replace />} />
          <Route path='/staging/loader' element={UserAccess ? <Loader /> : <Navigate to="/authlogin" replace />} />
          <Route path='/staging/previewsidebar' element={UserAccess ? <Previewsidebar /> : <Navigate to="/authlogin" replace />} />
          <Route path='/staging/privacy_policy' element={UserAccess ? <PrivacyPolicy /> : <Navigate to="/authlogin" replace />} />
          <Route path='/staging/updateitem' element={UserAccess ? <Updateitem /> : <Navigate to="/authlogin" replace />} />
        </Routes>
      </Router> */}

      <Router>
        <Routes>
          {/* <Route path='/login' element={!AccessUserId ? <Logins /> : <Navigate to="/dashboard" replace />} /> */}
          <Route path='/login' element={!AccessUserId ? <Logins /> : <Navigate to="/dashboard" replace />} />
          <Route element={<Navigate to="login" />} path="/" />
          {/* <Route path='/login' element={ <Logins />} /> */}
          <Route path='/invoice' element={<Invoices />} />
          <Route path='/simple-invoice-template' element={<Newinvoice />} />
          <Route path='/professional-invoice-template' element={<Secondinvoice />} />
          <Route path='/creative-invoice-template' element={<Threeinvoice />} />
          <Route path='/colorful-invoice-template' element={<Fourthtemplate />} />
          <Route path='/standard-invoice-template' element={<Fivetemplate />} />
          <Route path='/classic-invoice-template' element={<Sixtemplate />} />
          <Route path='/modern-invoice-template' element={<Sevantemplate />} />
          {<Route path='/list-your-business' element={<Details />} />}
          <Route path='/subscription' element={<Subscriptions />} />
          <Route path='/invoices' element={<Invoicedatas />} />
          <Route path='/myinvoice' element={<Invoice />} />
          <Route path='/invoicepreview' element={<InvoicePrivew />}/>
          <Route path='/currency' element={<Currancys />} />
          <Route path='/listed-business' element={<Bussiness />} />
          <Route path='/customers' element={<Customers />} />
          <Route path='/sold-products' element={<Solditems />} />
          <Route path='/session' element={<Sessions />} />
          <Route path='/generate-invoice' element={<CustomerForm />} />
          {/* <Route path='/dashboard' element={AccessUserId ?  <Dashbord /> : <Navigate to="/login" replace />} /> */}
          <Route path='/dashboard' element={<Dashbord />} />
          <Route path='/loader' element={<Loader />} />
          <Route path='/previewsidebar' element={<Previewsidebar />} />
          <Route path='/privacy_policy' element={<PrivacyPolicy />} />
          <Route path='/updateitem' element={<Updateitem />} />
          <Route path='/privacy-statement' element={<PrivacyPolicy />} />
          <Route path='/data-deletion-instruction' element={<Datadeletion />} />
          <Route path='/terms-of-use' element={<Tearmsofuse />} />
          <Route path='/refund-policy' element={<Refundcancelling />} />
          <Route path='/contact-us' element={<Contactus />} />
          <Route path='/devlopment-popups' element={<Devlopmentpoppup />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/payment-successfully' element={<Success />} />
          <Route path='/reject' element={<Rejectpopupss />} />
        </Routes>
      </Router>
    </>
  );
}

